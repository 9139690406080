$(document).ready(function() {


    // $(".home-block-4").css("border", (21-calcBorderFirst)+'vw solid white');

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        var calc=((100 + scroll/40)/100);
        var calcRight=(-15-(scroll/30));
        var calcLeft=(0-(scroll/50));

        if(calc<=2){
            $(".castle").css({
                transform: 'scale('+calc+')',
            });
        }
    });

    $("#carousel-block-3").on('slide.bs.carousel', function(event){
        var prevPos=event.from;
        var nextPos=event.to;

        $('#cuvee-'+prevPos).fadeOut('slow', function() {
            $('#cuvee-'+nextPos).fadeIn( "slow" );
        });

    });

    $('#carousel-block-3 .carousel-item').first().addClass('active');

});
